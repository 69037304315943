<template>
	<HeadingX class="c-base-h1" v-bind="{ tag }" level-offset="0">
		<template v-if="domProps.textContent">
			{{ domProps.textContent }}
		</template>
		<template v-else>
			<slot></slot>
		</template>
	</HeadingX>
</template>

<script>
import HeadingX from '~/citi-baseline/components/HeadingX';

export default {
	name: 'BaseH1',
	components: { HeadingX },

	props: {
		tag: {
			type: String,
			default: undefined,
		},
	},

	computed: {
		domProps() {
			const { domProps } = this.$vnode?.data || {};
			return domProps || {};
		},
	},
};
</script>

<style lang="postcss">
:where(.c-base-h1) {
	@apply text-h1 font-darker-grotesque font-bold;
}
</style>
